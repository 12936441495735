import React, { useEffect } from 'react'
import Layout from "../components/App/Layout"
import Navbar from "../components/App/Navbar"
import Footer from "../components/App/Footer"

const TermsOfService = () => {

    useEffect(() => {
        (function(d, s, id) {
            var js, tjs = d.getElementsByTagName(s)[0];
            if (d.getElementById(id)) return;
            js = d.createElement(s); js.id = id;
            js.src = "https://app.termly.io/embed-policy.min.js";
            tjs.parentNode.insertBefore(js, tjs);
          }(document, 'script', 'termly-jssdk'));
    });

    return (
        <Layout>
            <Navbar />
            <section className="terms-of-service-area ptb-100">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="terms-of-service-content">
                                <div name="termly-embed" data-id="30bab358-6449-48c7-b667-6ac95fd05aff" data-type="iframe"></div>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
            <Footer />
        </Layout>
    )
}

export default TermsOfService;